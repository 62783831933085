<!--
* @description:
* @fileName pageConfig.vue
* @author hvv
* @date 2022/01/13 15:21:21
!-->
<template>
  <div>
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button>
        <!-- <el-button :icon="Delete" type="danger" @click="handleDelete($event)">
          批量删除
        </el-button> -->
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <!-- <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form> -->
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="邮箱" prop="email" show-overflow-tooltip />
      <el-table-column label="手机号" prop="phone" show-overflow-tooltip />
      <el-table-column label="账号" prop="userName" show-overflow-tooltip />
      <el-table-column label="操作" show-overflow-tooltip width="400">
        <template #default="{ row }">
          <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="settings-6-line" />
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNumber"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="editRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
    onActivated,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import {
    getSynergyAccount,
    deleteSynergyAccount,
  } from '@/api/exhibitionManage'
  import { useRoute } from 'vue-router'
  export default defineComponent({
    name: 'exHibitionPageConfig',
    components: {
      Edit: defineAsyncComponent(() => import('./components/AccountEdit.vue')),
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        queryForm: { meetingCode: '' },
        total: 0,
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
      })

      const route = useRoute()
      const handleEdit = (row) => {
        if (row.id) {
          state.editRef.showEdit(row)
        } else {
          state.editRef.showEdit()
        }
      }

      const handleConfig = (row) => {
        proxy.$router.push(
          `/exhibitionManage/exhibitionPageConfigModule/${row.id}?meetingCode=${route.query.meetingCode}`
        )
      }

      const handleDelete = (row) => {
        if (row.uid) {
          proxy.$baseConfirm('你确定要删除当前子账户吗', null, async () => {
            const { msg } = await deleteSynergyAccount({ iamUid: row.uid, meetingCode: route.query.meetingCode})
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
      }

      const queryData = () => {
        fetchData()
      }

      const fetchData = async () => {
        state.listLoading = true
        state.queryForm.meetingCode = route.query.meetingCode
        const { data } = await getSynergyAccount(state.queryForm)
        state.list = data
        state.listLoading = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        fetchData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNumber = val
        fetchData()
      }

      const shopStatusChange = async (id, status) => {
        let statusKey = status ? 'ACTIVATION' : 'OFFLINE'
        try {
          await pageStatus(id, statusKey)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
        } catch (e) {
          console.log(e)
        }

        fetchData()
      }

      const handleSetHomePage = async (row) => {
        let params = {
          meetingCode: route.query.meetingCode,
          pageId: row.id,
        }
        try {
          await setExhHomePage(params)
          proxy.$baseMessage(`设置成功`, 'success', 'vab-hey-message-success')
          fetchData()
        } catch (e) {
          proxy.$baseMessage(`设置失败`, 'error', 'vab-hey-message-error')
        }
      }
      onActivated(() => {
        fetchData()
      })

      return {
        ...toRefs(state),
        handleEdit,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        shopStatusChange,
        handleConfig,
        Delete,
        Search,
        Plus,
        handleSetHomePage,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
